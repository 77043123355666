import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';  // Import Bootstrap components
import { db } from "../../firebase-config"; 
import { getDocs, collection, setDoc, updateDoc, doc, writeBatch, addDoc, getDoc, Timestamp } from "firebase/firestore";
import Select from 'react-select'; // Import react-select
import makeAnimated from 'react-select/animated'; // Import animated components for select
import { ToastContainer, toast } from 'react-toastify';

const animatedComponents = makeAnimated();

const TradingForm = ({ onClose, onSuccess = () => {}  }) => {
  const [users, setUsers] = useState([]);
  const [coins, setCoins] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // Store selected users
  const [selectedCoin, setSelectedCoin] = useState("");
  const [percentage, setPercentage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = await getDocs(collection(db, "users"));
      const userOptions = usersCollection.docs.map(doc => ({
        value: doc.id,  // Store the user ID as the value
        label: doc.data().name,  // Display the user's name in the select dropdown
      }));
      setUsers(userOptions);
    };

    const fetchCoins = async () => {
      const coinsCollection = await getDocs(collection(db, "coins"));
      const coinOptions = coinsCollection.docs.map(doc => ({
        value: doc.id,  // Store the Firestore document ID as the value
        label: `${doc.data().symbol} - ${doc.data().price} CHF`,  // Store the symbol and price as the label
        symbol: doc.data().symbol,  // Store the coin symbol (e.g., BTC)
      }));
      setCoins(coinOptions);
    };

    fetchUsers();
    fetchCoins();
  }, []);

    // Predefined distribution percentages
    const coinDistribution = {
      BTC: 0.20,     
      ETH: 0.10,   
      SOL: 0.08,  
      USDT: 0.08,    
      FET: 0.05, 
      RSC: 0.02, 
      GFAL: 0.01,    
      CGPT: 0.02,    
      GSWIFT: 0.01,  
      INJ: 0.02, 
      RON: 0.02, 
      PORTAL: 0.02,  
      ALU: 0.01, 
      GTAI: 0.01,    
      IMX: 0.01, 
      ZETA: 0.02,    
      ORNJ: 0.01,    
      NMT: 0.02, 
      ONDO: 0.01,    
      ARKM: 0.01,    
      PRIME: 0.01,   
      MAGIC: 0.01,   
      BEAM: 0.01,    
      SAND: 0.01,    
      TON: 0.03, 
      SNT: 0.01, 
      AIOZ: 0.01,    
      CUDOS: 0.01,   
      LINK: 0.01,    
      ATOM: 0.01,    
      ALGO: 0.02,    
      AAVE: 0.02,    
      DOT: 0.02, 
      MATIC: 0.02,   
      QNT: 0.03, 
      PASG: 0.01,    
      ROOT: 0.01,    
      ORAI: 0.01,    
      MAVIA: 0.01,
    };
  
   // Fetch the user's wallet (including coinData and cryptoValue)
const fetchUserWallet = async (userId) => {
  const walletRef = collection(db, `users/${userId}/wallet`);
  const walletSnapshot = await getDocs(walletRef);

  const userWallet = {};
  walletSnapshot.forEach((doc) => {
    const data = doc.data();
    userWallet[doc.id] = {
      coinData: data.coinData,   
      cryptoValue: data.cryptoValue  // This is the crypto amount
    };
  });

  return userWallet;  
};

    const fetchCoinPrices = async () => {
      const coinsRef = collection(db, 'coins');
      const coinsSnapshot = await getDocs(coinsRef);
      const coinPrices = {};
      coinsSnapshot.forEach((doc) => {
        const data = doc.data();
        coinPrices[data.symbol] = data.price;  // Map the symbol (e.g., BTC) to its price
      });
      return coinPrices;  // e.g., { BTC: 60000, ETH: 4000, SOL: 100 }
    };
  const calculateTotalBalance = (walletData, coinsData, totalTrading) => {
    let totalBalance = 0;
    walletData.forEach((coin) => {
      const coinPrice = coinsData[coin.coinData.symbol];
      if (coinPrice) {
        totalBalance += coin.cryptoValue * coinPrice;
      }
    });
    totalBalance += totalTrading;
    return totalBalance;

  };
  const calculateProfitFromBalance = (totalBalance, profitPercentage) => {
    return totalBalance * (profitPercentage / 100);
  };
  
    // Distribute profit across coins based on percentages
    const distributeProfitInCHF = (profitInCHF) => {
      const distributedProfit = {};
      Object.keys(coinDistribution).forEach((coin) => {
        const amountForCoin = profitInCHF * coinDistribution[coin]; 
        distributedProfit[coin] = amountForCoin;  
      });
      return distributedProfit;  
    };
  
const convertToCoinValue = (amountInCHF, coinPrice) => {
  if (!coinPrice || coinPrice <= 0) {
    return NaN;  
  }
  const coinValue = amountInCHF / coinPrice;  
  return coinValue;
};

// Function to update the user's wallet in Firestore (with added logging for debugging)
const updateUserWallet = async (userId, updatedWallet) => {
  const walletRef = collection(db, `users/${userId}/wallet`);

  // Loop over the updated wallet entries
  for (const walletDocId of Object.keys(updatedWallet)) {
    const { additionalCoinValue, coinData } = updatedWallet[walletDocId];  // Get the profit (additionalCoinValue) and coinData (with symbol)

    // Add a check to ensure coinData and coinData.symbol are present
    if (!coinData || !coinData.symbol) {
      console.log(`Missing coin data or symbol for wallet entry: ${walletDocId}`);
      continue;  
    }

    const coinDocRef = doc(walletRef, walletDocId);

    try {
      const docSnapshot = await getDoc(coinDocRef);

      if (docSnapshot.exists()) {
        // Retrieve and parse existing crypto value as a number
        const existingCryptoValue = Number(docSnapshot.data().cryptoValue);
        const additionalValue = Number(additionalCoinValue);

        // Validate that the values are numbers to avoid issues
        if (isNaN(existingCryptoValue) || isNaN(additionalValue)) {
          console.log(`Invalid numeric values for wallet entry: ${walletDocId}`);
          continue;
        }

        const updatedCryptoValue = existingCryptoValue + additionalValue;

        await updateDoc(coinDocRef, {
          cryptoValue: updatedCryptoValue,  
          updatedAt: Timestamp.now(),  
        });
        console.log(`Updated ${coinData.symbol} wallet: new cryptoValue is ${updatedCryptoValue}`);
      } else {
        console.log(`Document does not exist for wallet entry: ${walletDocId}`);
      }
    } catch (error) {
      console.error(`Error updating wallet entry ${walletDocId}:`, error);
    }
  }
};




const handleSubmit = async (e) => {
  e.preventDefault();
  if (!selectedUsers.length || !percentage || !selectedCoin) {
    toast.error('Please select users, coin, and enter a percentage');
    return;
  }
  setIsLoading(true);
  try {
    const [coinPrices, selectedCoinData] = await Promise.all([
      fetchCoinPrices(),
      coins.find(coin => coin.value === selectedCoin)
    ]);

    if (!selectedCoinData) {
      toast.error("Selected coin not found.");
      return;
    }
    
    const selectedCoinSymbol = selectedCoinData.symbol;

    for (const user of selectedUsers) {
      const userId = user.value;

      // Fetch user wallet and other data in parallel
      const [userWallet, userDoc] = await Promise.all([
        fetchUserWallet(userId),
        getDoc(doc(db, "users", userId))
      ]);

      const totalTrading = userDoc.data()?.totalTrading || 0;
      const totalBalance = calculateTotalBalance(Object.values(userWallet), coinPrices, totalTrading);
      const profitInCHF = calculateProfitFromBalance(totalBalance, percentage);
      const roundedProfitInCHF = parseFloat(profitInCHF.toFixed(2));

      // Add a new entry to the trading history
      const newTradeDocData = {
        coinData: {
          color: selectedCoinData.color || "#000000",
          isFavorite: selectedCoinData.isFavorite || false,
          order: selectedCoinData.order || 0,
          symbol: selectedCoinSymbol,
        },
        createdAt: Timestamp.now(),
        currencyCode: "CHF",
        tradingValue: roundedProfitInCHF,
        type: "AUTO_TRADE",
      };

      const tradingHistoryRef = collection(db, `users/${userId}/tradingHistory`);
      await addDoc(tradingHistoryRef, newTradeDocData);

      // Distribute profit among coins and calculate updates
      const distributedProfitInCHF = distributeProfitInCHF(profitInCHF);
      const batch = writeBatch(db);  // Initialize a batch for Firestore updates

      Object.keys(distributedProfitInCHF).forEach((coinSymbol) => {
        const walletEntry = Object.entries(userWallet).find(
          ([, walletCoin]) => walletCoin.coinData?.symbol === coinSymbol
        );
        if (!walletEntry) return;

        const [walletDocId, walletCoin] = walletEntry;
        const coinPrice = coinPrices[coinSymbol];
        if (!coinPrice) return;

        const amountInCHF = distributedProfitInCHF[coinSymbol];
        const additionalCoinValue = convertToCoinValue(amountInCHF, coinPrice);
        if (isNaN(additionalCoinValue)) return;

        const coinDocRef = doc(db, `users/${userId}/wallet`, walletDocId);
        const newCryptoValue = walletCoin.cryptoValue + additionalCoinValue;

        // Batch the update for efficiency
        batch.update(coinDocRef, {
          cryptoValue: newCryptoValue,
          updatedAt: Timestamp.now(),
        });
      });

      // Commit all updates in a single batch for this user
      await batch.commit();
    }
    
    handleCancel();
    toast.success('Aktion erfolgreich abgeschlossen!');
  } catch (error) {
    console.error('Error distributing profit:', error);
    toast.error('An error occurred while distributing the profit.');
  } finally {
    setIsLoading(false);  // End loading indicator
  }
};

  const handleCancel = () => {
    onClose();    // Close the modal
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',  // Set background to transparent
      border: '1px solid #ccc',        // Optional: Adjust the border
      boxShadow: 'none',               // Remove box shadow
      color: '#fff',                   // Optional: Set text color
      fontSize: '14px',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',      // Change dropdown menu background (optional)
      fontSize: '14px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#333' : '#222',  // Highlight on hover (optional)
      color: '#fff',                                           // Text color for options
    }),
    input: (provided) => ({
        ...provided,
        color: '#fff',  // Change text input color (unselected text)
        fontSize: '14px',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#fff',  // Change text color for single value
      }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#333',       // Background color of selected items (optional)
      fontSize: '14px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',                    // Color of text in selected items
      fontSize: '14px',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      backgroundColor: '#3e3e3e',       // Background color of remove icon (optional)
      ':hover': {
        backgroundColor: '#3e3e3e',     // Background color on hover (optional)
        color: '#fff',
      },
    }),
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
      <label>
      BENUTZER AUSWÄHLEN:
        {/* Replace HTML select with react-select */}
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={users}  // The options for the users
          onChange={setSelectedUsers}  // Handle the selection change
          placeholder="BENUTZER AUSWÄHLEN..."
          styles={customStyles}
        />
      </label>

      <label>
      PROZENTSATZ:
        <input
          type="number"
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
        />
      </label>

      <label>
  CION AUSWÄHLEN:
  <select value={selectedCoin} onChange={(e) => setSelectedCoin(e.target.value)}>
    {coins.map((coin) => (
      <option key={coin.value} value={coin.value}>
        {coin.symbol} {coin.currencyCode}
      </option>
    ))}
  </select>
</label>
      <div className="bttonsB">
      <Button variant="secondary" type="button" onClick={handleCancel}>
          Verwerfen
        </Button>

        {/* Submit button to create users */}
        <Button className="my-4" color="primary" type="submit" disabled={isLoading}>
        {isLoading ? "Verarbeitung..." : "Auswählen"}
        </Button>
        </div>
    </form>
    {isLoading && <p>Laden, bitte warten...</p>}
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
    </>
  );
};

export default TradingForm;
